import Favicon from 'react-favicon';

import citypostIco from '../../assets/img/favicons/citypost.ico';
import ecologisticIco from '../../assets/img/favicons/ecologistic.ico';
import kolporterIco from '../../assets/img/favicons/kolporter.ico';
import logistarIco from '../../assets/img/favicons/logistar.ico';
import novapostaIco from '../../assets/img/favicons/novaposta.ico';
import postexpressIco from '../../assets/img/favicons/postexpress.ico';
import totalpostlogisticIco from '../../assets/img/favicons/totalpostlogistic.ico';
import koderIco from '../../assets/img/logos/koder-icon.svg';
import myboxIco from '../../assets/img/favicons/mybox.ico';

const favicons = {
  koder: koderIco,
  novaposta: novapostaIco,
  ecologistic: ecologisticIco,
  citypost: citypostIco,
  postexpress: postexpressIco,
  kolporter: kolporterIco,
  logistar: logistarIco,
  totalpost: totalpostlogisticIco,
  totalposttest: totalpostlogisticIco,
  mybox: myboxIco,
};

function FaviconLoader(): JSX.Element {
  const clientName =
    (process.env.REACT_APP_CLIENT_NAME as keyof typeof favicons) ?? 'koder';

  return <Favicon url={favicons[clientName] || koderIco} />;
}

export default FaviconLoader;
