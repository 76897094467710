import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';

import { noColumnStyle } from '../../../configs/primereact';
import { ClientImportListTypeCollection } from '../../../types/api/clients';
import { Unpacked } from '../../../types/util';
import ActionsTableMenu from '../../Components/ActionsTableMenu/ActionsTableMenu';

export const tableStorageKey = 'listTypes_dataTable';

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    name: t('Name'),
  };
}

export function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getColumnHeadersMap> | 'actions',
  setContextMenuSelection: Dispatch<SetStateAction<any>>,
  setAction: any
) {
  switch (column) {
    case 'no':
      return {
        noColumnStyle,
        style: {
          width: 30,
          maxWidth: 30,
        },
        bodyStyle: {
          width: 30,
          maxWidth: 30,
          display: 'flex',
          justifyContent: 'center',
        },
      };

    case 'name':
      return {
        style: {
          width: 'auto',
        },
        bodyStyle: {
          width: 'auto',
        },
      };

    case 'actions':
      return {
        body: (row: Unpacked<ClientImportListTypeCollection>) => {
          return (
            <ActionsTableMenu
              actions={[
                {
                  label: t('Export to Excel'),
                  icon: 'fas fa-file-excel',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('export-to-excel');
                  },
                },
                {
                  label: t('Export to CSV'),
                  icon: 'fas fa-file-csv',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('export-to-cvs');
                  },
                },
              ]}
            />
          );
        },
        style: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
        },
        bodyStyle: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
          justifyContent: 'center',
        },
      };

    default:
      return {};
  }
}
