import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-lodash-debounce';

import useAxios from '../../../../hooks/useAxios';
import usePrevious from '../../../../hooks/usePrevious';
import { CalculatorResponse } from '../../../../types/api/orders';
import { ReduxState } from '../../../../types/redux';
import { calculatorPaymentToPayment } from '../../../../utils/api/orders';
import { debounceTimeout } from '../../../../utils/constants/misc';
import { unary } from '../../../../utils/helpers/functions';
import { cleanUpObject } from '../../../../utils/helpers/object';
import {
  Action,
  FormFields,
  getCalculatorData,
} from './CreateEditRecreate.functions';

type Return = {
  isLoadingCalculator: boolean;
};

function useCalculator(
  methods: UseFormReturn<FormFields>,
  defaultValues: FormFields,
  action: Action,
  isLoading: boolean
): Return {
  const isEditing = action === 'edit';

  const loggedInUserClientId = useSelector<
    ReduxState,
    ReduxState['user']['client_id']
  >((s) => s.user.client_id);

  const { control, setValue } = methods;

  const payments = useWatch<FormFields, 'payments'>({
    name: 'payments',
    control,
  });

  const [
    proizvod_id,
    klient_od_id,
    klient_do_id,
    ulica_od_ime,
    ulica_do_ime,
    adresa_od,
    adresa_do,
    mesto_od_ime,
    mesto_od_id,
    mesto_do_ime,
    mesto_do_id,
    broj_od,
    broj_do,
    povraten_dokument,
    replacement_shipment,
    tezina,
    otkup,
    vrednost,
    hub_od_id,
    hub_do_id,
    pickup_location_type_id,
    delivery_location_type_id,
    volumen,
    kolicina,
    delivery_term_id,
    priority,
    personal_delivery,
  ] = useWatch<
    FormFields,
    [
      'proizvod_id',
      'klient_od_id',
      'klient_do_id',
      'ulica_od_ime',
      'ulica_do_ime',
      'adresa_do',
      'adresa_od',
      'mesto_od_ime',
      'mesto_od_id',
      'mesto_do_ime',
      'mesto_do_id',
      'broj_od',
      'broj_do',
      'povraten_dokument',
      'replacement_shipment',
      'tezina',
      'otkup',
      'vrednost',
      'hub_od_id',
      'hub_do_id',
      'pickup_location_type_id',
      'delivery_location_type_id',
      'volumen',
      'kolicina',
      'delivery_term_id',
      'priority',
      'personal_delivery'
    ]
  >({
    name: [
      'proizvod_id',
      'klient_od_id',
      'klient_do_id',
      'ulica_od_ime',
      'ulica_do_ime',
      'adresa_do',
      'adresa_od',
      'mesto_od_ime',
      'mesto_od_id',
      'mesto_do_ime',
      'mesto_do_id',
      'broj_od',
      'broj_do',
      'povraten_dokument',
      'replacement_shipment',
      'tezina',
      'otkup',
      'vrednost',
      'hub_od_id',
      'hub_do_id',
      'pickup_location_type_id',
      'delivery_location_type_id',
      'volumen',
      'kolicina',
      'delivery_term_id',
      'priority',
      'personal_delivery',
    ],
    control,
  });

  const defaultCalculatorData = useMemo(
    () => getCalculatorData(defaultValues, loggedInUserClientId),
    [defaultValues, loggedInUserClientId]
  );

  const calculatorData = useMemo(() => {
    return getCalculatorData(
      {
        proizvod_id,
        klient_od_id,
        klient_do_id,
        ulica_od_ime,
        ulica_do_ime,
        adresa_od,
        adresa_do,
        mesto_od_ime,
        mesto_od_id,
        mesto_do_ime,
        mesto_do_id,
        broj_od,
        broj_do,
        povraten_dokument,
        replacement_shipment,
        tezina,
        otkup,
        vrednost,
        hub_od_id,
        hub_do_id,
        pickup_location_type_id,
        delivery_location_type_id,
        volumen,
        payments,
        kolicina,
        delivery_term_id,
        priority,
        personal_delivery,
      },
      loggedInUserClientId
    );
  }, [
    adresa_do,
    adresa_od,
    broj_do,
    broj_od,
    delivery_location_type_id,
    hub_do_id,
    hub_od_id,
    klient_do_id,
    klient_od_id,
    kolicina,
    loggedInUserClientId,
    mesto_do_id,
    mesto_do_ime,
    mesto_od_id,
    mesto_od_ime,
    otkup,
    payments,
    pickup_location_type_id,
    povraten_dokument,
    replacement_shipment,
    proizvod_id,
    tezina,
    ulica_do_ime,
    ulica_od_ime,
    volumen,
    vrednost,
    delivery_term_id,
    priority,
    personal_delivery,
  ]);

  const isCalculatorDataEqualToItsDefaultValue = useMemo(
    () => _.isEqual(calculatorData, defaultCalculatorData),
    [calculatorData, defaultCalculatorData]
  );

  const debouncedCalculatorData = useDebounce(cleanUpObject(calculatorData), 500);

  const {
    data: reqData,
    error: reqError,
    isLoading: isLoadingCalculator,
  } = useAxios<CalculatorResponse>(
    {
      url: '/orders/calculator',
      method: 'POST',
      data: debouncedCalculatorData,
    },
    {
      skipWhen:
        !debouncedCalculatorData ||
        !calculatorData.pickup_city ||
        !calculatorData.delivery_city ||
        !calculatorData.product_id ||
        (isEditing && isLoading) ||
        (isEditing && isCalculatorDataEqualToItsDefaultValue),
    }
  );
  const debouncedReqData = useDebounce(reqData, debounceTimeout);
  const prevReqData = usePrevious(debouncedReqData);
  const prevReqError = usePrevious(reqError);

  useEffect(() => {
    if (!reqData || _.isEqual(reqData, prevReqData)) {
      return;
    }

    setValue('payments', reqData.map(unary(calculatorPaymentToPayment)));
  }, [reqData]); // only reqData like depends.. because we don't want to call useEffect when previous reqData changed

  useEffect(() => {
    if (!reqError || reqError === prevReqError) {
      return;
    }

    setValue(
      'payments',
      isEditing && isCalculatorDataEqualToItsDefaultValue
        ? defaultValues.payments
        : []
    );
  }, [
    defaultValues.payments,
    isCalculatorDataEqualToItsDefaultValue,
    isEditing,
    prevReqError,
    reqError,
    setValue,
  ]);

  useEffect(() => {
    if (isEditing && isCalculatorDataEqualToItsDefaultValue) {
      setValue('payments', defaultValues.payments);
    }
  }, [
    defaultValues.payments,
    isCalculatorDataEqualToItsDefaultValue,
    isEditing,
    setValue,
  ]);

  return {
    isLoadingCalculator,
  };
}

export default useCalculator;
