import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';

import { noColumnStyle } from '../../../configs/primereact';
import { currencyFormat } from '../../../utils/helpers/formatting';
import ActionsTableMenu from '../../Components/ActionsTableMenu/ActionsTableMenu';

export type COD = {
  id: number;
  klient_id: number;
  adresnica_id: number;
  datum: string;
  opis: string;
  seriski: string;
  adresnica_seriski: string;
  rabotnik_id: number;
  status: number;
  email_status_id: number;
  email_sent_date: string | null;
  hub_id: number;
  client_name: string;
  employee_name: string;
  hub_name: string;
  vkupen_otkup: number;
  adresnica_status: string;
};

export const tableStorageKey = 'warrants_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): Record<
  | keyof Pick<
      COD,
      | 'datum'
      | 'adresnica_seriski'
      | 'seriski'
      | 'client_name'
      | 'adresnica_status'
      | 'vkupen_otkup'
    >
  | 'no',
  string
> {
  return {
    no: t('No.'),
    datum: t('Date'),
    adresnica_seriski: t('Order Serial No.'),
    seriski: t('Serial No.'),
    client_name: t('Client'),
    adresnica_status: t('Status'),
    vkupen_otkup: t('Redemption'),
  };
}

export function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getColumnHeadersMap> | 'actions',
  setContextMenuSelection: Dispatch<SetStateAction<any>>,
  setAction: any
) {
  switch (column) {
    case 'no':
      return {
        noColumnStyle,
        style: {
          width: 30,
          maxWidth: 30,
        },
        bodyStyle: {
          width: 30,
          maxWidth: 30,
          display: 'flex',
          justifyContent: 'center',
        },
      };

    case 'vkupen_otkup':
      return {
        body: (row: COD) =>
          currencyFormat(row.vkupen_otkup, { showCurrency: true }),
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };

    case 'actions':
      return {
        body: (row: COD) => {
          return (
            <ActionsTableMenu
              actions={[
                {
                  label: t('View Orders'),
                  icon: 'fas fa-search',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('view-orders');
                  },
                },
                {
                  label: t('Export to CSV'),
                  icon: 'fas fa-file-csv',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('export-to-cvs');
                  },
                },
                {
                  label: t('Export to Excel'),
                  icon: 'fas fa-file-excel',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('export-to-excel');
                  },
                },
                {
                  label: t('Export to PDF'),
                  icon: 'fas fa-file-pdf',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('export-to-pdf');
                  },
                },
              ]}
            />
          );
        },
        style: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
        },
        bodyStyle: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
          justifyContent: 'center',
        },
      };

    default:
      return {
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };
  }
}
