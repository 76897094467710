import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OrderType } from '../../../../../../../types/api/orders';
import { LabelValue } from '../../../../../../../types/options';
import FileUpload from '../../../../../../Components/UploadFile/UploadFile';
import FieldWithErrors from '../../../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import { FormFields } from '../../../CreateEditRecreate.functions';

function ReturnDocument({
  returnDocumentOptionsData,
  setFileForUpload,
}: {
  returnDocumentOptionsData?: LabelValue[];
  setFileForUpload?: Function;
}): JSX.Element {
  const { t } = useTranslation();

  const proizvod_id = useWatch<FormFields, 'proizvod_id'>({
    name: 'proizvod_id',
  });

  const povraten_dokument = useWatch<FormFields, 'povraten_dokument'>({
    name: 'povraten_dokument',
  });

  const return_document_type_id = useWatch<
    FormFields,
    'return_document_type_id'
  >({
    name: 'return_document_type_id',
  });

  return (
    <>
      <FieldWithErrors
        name="povraten_dokument"
        label={t('Return document')}
        className="data-cy-return_document"
      >
        <Controller
          name="povraten_dokument"
          render={({ field }) => (
            <InputSwitch
              {...field}
              checked={Boolean(field.value)}
              inputId={field.name}
            />
          )}
        />
      </FieldWithErrors>
      {povraten_dokument && proizvod_id === OrderType.Package && (
        <FieldWithErrors
          name="return_document_type_id"
          label={t('Type of return document')}
        >
          <Controller
            name="return_document_type_id"
            render={({ field }) => (
              <Dropdown
                name="return_document_type_id"
                inputId="return_document_type_id"
                options={returnDocumentOptionsData}
                placeholder={t('Select')}
                value={field.value}
                showClear
                onChange={(e) => field.onChange(e.value)}
                className="data-cy-return-document-type-id"
              />
            )}
          />
        </FieldWithErrors>
      )}
      {parseInt(return_document_type_id) === 3 && (
        <FileUpload setFileForUpload={setFileForUpload!} />
      )}
    </>
  );
}

export default ReturnDocument;
