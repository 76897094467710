import ecologisticLogoBig from '../assets/img/logos/ecologistic-big.png';
import ecologisticLogo from '../assets/img/logos/ecologistic.png';
import koderLogo from '../assets/img/logos/koder.svg';
import kolporterLogoBig from '../assets/img/logos/kolporter-big.png';
import kolporterLogo from '../assets/img/logos/kolporter.png';
import logistarLogoBig from '../assets/img/logos/logistar-big.png';
import logistarLogo from '../assets/img/logos/logistar.png';
import TotalpostlogisticLogo from '../assets/img/logos/totalpostlogistic.png';
import MyBoxLogo from '../assets/img/logos/mybox.png';
import { ClientPost } from '../enums/clients';

export const logos: Record<ClientPost, string> = {
  [ClientPost.Koder]: koderLogo,
  [ClientPost.ELS]: ecologisticLogo,
  [ClientPost.Kolporter]: kolporterLogo,
  [ClientPost.Logistar]: logistarLogo,
  [ClientPost.Totalpost]: TotalpostlogisticLogo,
  [ClientPost.TotalpostTest]: TotalpostlogisticLogo,
  [ClientPost.MyBox]: MyBoxLogo,
};

export const logosBig: Record<ClientPost, string> = {
  [ClientPost.Koder]: koderLogo,
  [ClientPost.ELS]: ecologisticLogoBig,
  [ClientPost.Kolporter]: kolporterLogoBig,
  [ClientPost.Logistar]: logistarLogoBig,
  [ClientPost.Totalpost]: TotalpostlogisticLogo,
  [ClientPost.TotalpostTest]: TotalpostlogisticLogo,
  [ClientPost.MyBox]: MyBoxLogo,
};
