import './UploadFile.scss';

import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function FileUpload({
  setFileForUpload,
}: {
  setFileForUpload: Function;
}) {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState('');

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        accept="application/pdf"
        onChange={(e) => {
          const file = e.target.files?.[0];

          setFileForUpload!(file);
          setName(file?.name ?? '');
        }}
        className="hidden"
        style={{ display: 'none' }}
      />

      <label
        onClick={() => fileInputRef.current?.click()}
        className="file-upload-btn"
      >
        {name ? name : t('Attach PDF')}
      </label>
    </div>
  );
}
